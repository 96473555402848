<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <full-cart></full-cart>
      </div>
    </div>
  </div>
</template>
<script>
import FullCart from "../components/FullCart.vue";
export default {
  name: "CartPage",
  components: { FullCart },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.fullCart") + " | %s",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("title.fullCart"),
        },
      ],
    };
  },
};
</script>