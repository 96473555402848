<template>
  <div>
    <div class="project-list">
      <loading-page :isShow="isShowLoading"></loading-page>
      <div class="row">
        <div
          v-if="Object.keys(fullItems).length <= 0 && !isShowLoading"
          class="alert alert-danger"
        >
          <span>{{ $t("btn.cart_is_empty") }}</span>
        </div>
        <div v-if="fullItems.count > 0" class="mb-2 d-flex flex-wrap gap-2">
          <button
            @click.prevent="clearCart()"
            class="btn edc-btn-primary text-white py-2 px-3 rounded-pill"
          >
            <i class="fas fa-trash-alt ms-2"></i>
            <span>{{ $t("btn.empty_cart") }}</span>
          </button>
          <button
            @click.prevent="checkoutCart()"
            class="btn edc-btn-primary text-white py-2 px-3 rounded-pill"
          >
            <i class="fas fa-coins ms-2"></i>
            <span>{{ $t("btn.finish_donate") }}</span>
          </button>
          <div
            class="
              edc-btn-outline-red edc-color-secondary
              rounded-pill
              d-flex
              py-2
              px-3
            "
          >
            <span class="mx-1">{{ $t("label.total_cart") }}</span>

            {{ fullItems.total }}

            {{ fullItems.currency }}
          </div>
        </div>
        <!--project-item-->
        <div
          v-for="(item, index) in fullItems.items"
          :key="index"
          class="mb-4 col-lg-4 col-md-6"
        >
          <div class="project-list-post pb-4">
            <!--image-->
            <router-link :to="{ path: '/products/' + item.project.slug }">
              <div class="thumable p-2 position-relative flex-fill">
                <img
                  :src="item.project.image"
                  lazysizes
                  :data-src="item.project.image"
                  class="project-list-img img-fluid"
                  alt=""
                  srcset=""
                />
              </div>
            </router-link>
            <!--//image-->
            <div class="project-content-center">
              <!--title-->
              <h1
                class="project-list-title fs-5 fw-bold p-2 mb-3 text-truncate"
              >
                <router-link
                  :to="{ path: '/products/' + item.project.slug }"
                  :title="item.project.title"
                  >{{ item.project.title }}</router-link
                >
              </h1>
              <!--//title-->
              <!--progress-->
              <progress-bar
                :project_id="item.id"
                :percentage="item.progress.percent"
              ></progress-bar>
              <!--// progress-->
            </div>
            <div class="project-content-left">
              <!--project your donate price-->
              <div class="border-bottom border-1">
                <div
                  class="
                    rounded-pill
                    mx-auto
                    my-3
                    w-75
                    edc-btn-outline-red edc-color-secondary
                    fs-5
                    py-2
                    px-4
                    text-center
                    d-flex
                    justify-content-start
                  "
                >
                  <span class="flex-fill"
                    >{{ $t("label.your_donate") }} :
                  </span>
                  <span
                    class="m-countup amount ms-1"
                    id="amount-20"
                    :data-count="item.amount"
                    >{{ item.amount }}</span
                  >
                  <span>{{ item.project.currency }}</span>
                </div>
              </div>
              <!--//project your donate price-->
              <!--project price-->
              <div
                v-if="!item.project.is_completed"
                class="project-price px-3 mt-2 fs-7"
              >
                <div>{{ $t("label.edit_amount") }}</div>
                <div
                  class="
                    project-price-box
                    my-2
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <div
                    class="
                      project-price-box-up-down
                      bg-white
                      edc-border-primary
                      rounded-pill
                      d-flex
                      align-items-stretch align-self-stretch
                    "
                  >
                    <button
                      type="button"
                      @click.prevent="priceChange('+', item)"
                      :data-id="item.project.id"
                      class="
                        up
                        border-start border-0
                        bg-transparent
                        edc-color-green
                        px-3
                      "
                      aria-label="incress amount"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                    <button
                      type="button"
                      @click.prevent="priceChange('-', item)"
                      class="down border-0 bg-transparent edc-color-red px-3"
                      :data-id="item.project.id"
                      aria-label="decress amount"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                  <div
                    class="
                      project-price-box-input
                      d-flex
                      w-75 w-lg-100
                      justify-content-between
                      align-items-center
                      bg-white
                      flex-fill
                      me-1
                      edc-border-primary
                      px-3
                      py-1
                      rounded-pill
                    "
                  >
                    <input
                      type="text"
                      :id="'price-input-' + item.project.id"
                      class="
                        price_input
                        border-0
                        fs-5
                        flex-fill
                        w-75 w-lg-100
                        edc-color-green
                        bg-transparent
                      "
                      placeholder="00.000"
                      v-model="item.amount"
                    />
                    <span class="kd fs-4 align-middle">KD</span>
                  </div>
                </div>
              </div>
              <!--//project price-->
              <!--project buttons-->
              <div
                v-if="!item.project.is_completed"
                class="project-buttons px-3 mt-2"
              >
                <div class="d-grid gap-2">
                  <button
                    @click="doRefreshCart(item.id, item.amount)"
                    type="button"
                    data-id="20"
                    class="
                      refreshCart
                      btn
                      edc-btn-primary
                      bg-white
                      rounded-pill
                      btn-lg btn-block
                      py-3
                      text-center text-white
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i class="fas fa-sync-alt ms-2"></i>
                    <div class="fs-6">{{ $t("btn.update_project") }}</div>
                  </button>
                  <button
                    @click.prevent="deleteFromCart(item.id)"
                    type="button"
                    data-id="20"
                    class="
                      deleteFromCart
                      btn
                      edc-btn-outline-red
                      bg-white
                      rounded-pill
                      btn-lg btn-block
                      py-3
                      text-center text-muted
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <i
                      class="fas fa-trash mx-3 text-danger"
                      aria-hidden="true"
                    ></i>
                    <div class="fs-6 text-danger">
                      {{ $t("btn.delete_project") }}
                    </div>
                  </button>
                </div>
              </div>
              <!--//project buttons-->
              <!--project complited-->
              <div
                @click.prevent="deleteFromCart(item.project.id)"
                v-if="item.project.is_completed"
                class="
                  project-complited
                  text-center
                  px-3
                  mt-2
                  fs-7
                  edc-color-green
                "
              >
                <i class="fa fa-check-circle fs-1" aria-hidden="true"></i>
                <h3 class="fs-4 my-2">{{ $t("btn.project_is_complited") }}</h3>
              </div>
              <!--//project complited-->
            </div>
          </div>
        </div>
        <!--//project-item-->
        <div v-if="fullItems.count > 0" class="mb-2 d-flex gap-2 flex-wrap">
          <button
            @click.prevent="clearCart()"
            class="btn edc-btn-primary text-white py-2 px-3 rounded-pill"
          >
            <i class="fas fa-trash-alt"></i>
            <span class="mx-2">{{ $t("btn.empty_cart") }}</span>
          </button>
          <button
            @click.prevent="checkoutCart()"
            class="btn edc-btn-primary text-white py-2 px-3 rounded-pill"
          >
            <i class="fas fa-coins ms-2"></i>
            <span>{{ $t("btn.finish_donate") }}</span>
          </button>
          <div
            class="
              edc-btn-outline-red edc-color-secondary
              rounded-pill
              py-2
              px-3
              d-flex
            "
          >
            <span class="mx-1">{{ $t("label.total_cart") }}</span>

            {{ fullItems.total }}

            {{ fullItems.currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import { mapGetters, mapActions } from "vuex";
import LoadingPage from "./LoadingPage.vue";
import vueLazysizes from "vue-lazysizes";
export default {
  props: [],
  components: { ProgressBar, LoadingPage },
  computed: {
    ...mapGetters("cart", ["cart", "fullItems", "isShowLoading"]),
  },
  methods: {
    ...mapActions("cart", [
      "emptyCart",
      "deleteItem",
      "getFullCart",
      "updateCart",
      "addToCart",
    ]),
    deleteFromCart(id) {
      this.$swal({
        title: this.$t("message.are_you_sure"),
        // text: this.$t("message.will_delete_project"),
        icon: "warning",
        buttons: {
          cancel: this.$t("message.redo"),
          delete: {
            text: this.$t("message.yes_delete"),
            value: "delete",
          },
        },
      }).then((result) => {
        switch (result) {
          case "delete":
            this.deleteItem({ id: id });
            this.$swal({
              icon: "success",
              title: this.$t("message.delete_success"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            break;
        }
      });
      return false;
    },
    doRefreshCart(id, _amount) {
      this.addToCart({ id: id, amount: _amount });
      this.$swal({
        title: this.$t("message.update_success"),
        icon: "success",
        showCancelButton: true,
      });
    },
    priceChange(type, item) {
      if (type === "+") {
        if (item.amount < 10000) {
          item.amount++;
        } else {
          item.amount = 10000;
        }
      } else if (type === "-") {
        if (item.amount > 1) {
          item.amount--;
        } else {
          item.amount = 1;
        }
      }
    },
    clearCart() {
      this.$swal({
        title: this.$t("message.are_you_sure"),
        // text: this.$t("message.will_delete_project"),
        icon: "warning",
        buttons: {
          cancel: this.$t("message.redo"),
          delete: {
            text: this.$t("message.yes_delete"),
            value: "delete",
          },
        },
      }).then((result) => {
        switch (result) {
          case "delete":
            this.emptyCart();
            this.$swal({
              icon: "success",
              title: this.$t("message.empty_cart_success"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            break;
        }
      });
    },
    checkoutCart() {
      if (this.fullItems.total <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_ammount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.$root.$emit("doFastDonate", {
        amount: this.fullItems.total,
        project: localStorage.getItem("edc_cart"),
        isCart: true,
      });
    },
    inputChange(index, amount) {
      if (amount > 10000) {
        this.items[index]["amount"] = 10000;
      }
      if (amount < 1) {
        this.items[index]["amount"] = 1;
      }
    },
  },
  created() {
    this.getFullCart();
  },
  directives: {
    lazysizes: vueLazysizes
  },
};
</script>